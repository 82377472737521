import domReady from '@roots/sage/client/dom-ready';
import {RedirectToAppStore} from "@scripts/modules/RedirectToAppStore.js";

/**
 * Application entrypoint
 */
domReady(async() => {
    // Assign story id and node (episode id) to form fields, when present. These only show on the Report an Issue page.
    let storyIdField = document.querySelector('input#field_sid');
    let episodeIdField = document.querySelector('input#field_nid');

    if (storyIdField || episodeIdField) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const storyId = urlParams.get('sid');
        const episodeId = urlParams.get('nid');

        if (storyIdField && storyId) {
            storyIdField.value = parseInt(storyId);
        }

        if (episodeIdField && episodeId) {
            episodeIdField.value = parseInt(episodeId);
        }
    }

    let appStoreRedirect = document.querySelector('[data-app-store-redirect]');
    if (appStoreRedirect) {
        let options = {
            app_store_id: app_store_id,
            google_play_store_package_name: google_play_store_package_name,
        };

        new RedirectToAppStore(options);
    }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(console.error);
}
