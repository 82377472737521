export class RedirectToAppStore {
    constructor(options) {
        this.options = options;
        this.init();
    }

    init() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { // Detect iOS devices.
            window.location.href = 'https://apps.apple.com/app/id' + this.options.app_store_id;
        } else if (/android/i.test(userAgent)) { // Detect Android devices.
            window.location.href = 'https://play.google.com/store/apps/details?id='  + this.options.google_play_store_package_name;
        }
    }
}
